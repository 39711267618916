#root {
  /*zoom: var(--main-zoom);*/
}
body > iframe[style*="2147483647"] {
  display: none;
}
.appbar {
  background-color: #f7f6f6;
  height: 40px;
  border-bottom: 1px solid #dfdede;
  /*zoom: var(--main-zoom);*/
}

.logo {
  display: inline-block;
  margin: 7px 10px 0 10px;
  font-size: 20px;
  color: #606060;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.logo .cc-name {
  margin-left: 50px;
  text-shadow: -2px 2px 3px rgba(64, 58, 58, 0.4);
  cursor: auto;
  color: var(--main-color);
}

.logo1 {
  margin-bottom: 5px;
}

.logo2 {
  margin-bottom: 5px;
  margin-left: 10px;
}

.system-id {
  display: inline-block;
  font-size: 20px;
  color: rgb(161, 161, 161);
  position: absolute;
  left: 250px;
  top: 5px;
  background-color: white;
  border-radius: 5px;
  padding: 0 50px 0 5px;
}

.system-id-number {
  color: #000;
  font-weight: bold;
}

.navbar {
  min-height: 40px;
}

.navbar-header-left {
  width: 50%;
  display: inline-block;
  user-select: none;
}

.navbar-header-left .toggle {
  margin: 5px 10px 0 10px;
  font-size: 20px;
  display: inline-block;
}

.hamburger {
  cursor: pointer;
}

.navbar-header-right {
  float: right;
  display: inline-block;
  margin: 8px 20px 0 0;
}

.navbar-header-right .user {
  cursor: pointer;
  margin-right: 10px;
  margin-top: 0;
  width: 26px;
}

.navbar-header-right .dropdown {
  background-color: transparent;
  display: inline-block !important;
  border: none;
  margin-top: 0 !important;
}

.navbar-header-right .dropdown > button {
  padding: 0;
}

.navbar-header-right .dropdown .dropdown-menu {
  padding: 0;
  margin-left: -285px;
  font-size: 16px;
  width: 345px;
  border-radius: 0;
}

.navbar-header-right .dropdown .dropdown-item {
  padding: 0.6rem 1.25rem;
}

.navbar-header-right .dropdown .dropdown-item svg {
  color: #999;
}

.navbar-header-right .dropdown .dropdown-item:first-child {
  border-bottom: 1px solid #ddd;
}

.navbar-header-right .dropdown .dropdown-item:last-child {
  border-top: 1px solid #ddd;
}

.navbar-header-right .dropdown .dropdown-item:focus,
.navbar-header-right .dropdown .dropdown-item:hover {
  outline: none !important;
  background: var(--main-color);
  color: white;
  cursor: pointer;
}

.navbar-header-right .dropdown .dropdown-item .text {
  padding-left: 10px;
}

.navbar-header-right .dropdown > button::selection,
.dropdown > button:active,
.dropdown > button:visited,
.dropdown > button:hover,
.dropdown > button:focus {
  outline: none !important;
  /*background: #FE7069;*/
}

.navbar-header-right .toggle-user {
  background-color: transparent;
  display: inline-block;
  border: none;
}

.navbar-header-right .toggle-user:hover {
  background-color: transparent;
  display: inline-block;
  border: none;
}

.navbar-header-right .select {
  height: 22px;
  font-size: 12px;
  display: inline-block;
}

.navbar-header-right .select .esp-select {
  display: inline-block;
  width: 133px;
  height: 22px;
  margin: 0 30px 0 10px;
  vertical-align: bottom;
}

.navbar-header-right .select .esp-select .esp-select__control {
  height: 22px;
  min-height: 22px !important;
}

.navbar-header-right .select .esp-select .esp-select__indicators {
  padding: 0;
  margin-top: 0;
  min-height: 22px;
  height: 22px;
}

.navbar-header-right .select .esp-select .esp-select__dropdown-indicator {
  padding: 1px 0 0 0 !important;
  margin-top: -1px;
}

.navbar-header-right .select .esp-select__placeholder {
  margin-top: -1px;
}

.navbar-header-right .select .esp-select__menu {
  width: 200px;
}

.navbar-header-right img.language {
  margin-top: -2px;
}

.navbar-header-right .date {
  font-size: 10px;
  margin-left: 20px;
  padding: 3px 5px;
  background-color: #fff;
  border-radius: 3px;
  color: black;
}

.date2 {
  display: inline-block;
  font-size: 10px;
  margin-left: 20px;
  padding: 14px 5px 3px 5px;
  vertical-align: top;
  color: black;
}

.wrapper {
  width: 100vw;
}

#sidebar {
  display: inline-block;
  width: 12%;
  background-color: #f7f6f6;
  height: calc(100vh - 40px);
  border-right: 1px dashed rgb(214, 214, 214);
  position: relative;
  font-size: 20px;
  overflow: auto;
}

#sidebar .sidebar-header {
  color: var(--main-color);
  text-align: center;
  font-weight: bold;
  background-color: white;
  height: 40px;
  padding-top: 5px;
  zoom: var(--main-zoom);
}

#sidebar .sidebar-header-left {
  text-align: left;
  padding-left: 10px;
}

#sidebar .sidebar-body {
  color: black;
  line-height: 2.2;
  margin-top: 2%;
  zoom: var(--main-zoom);
}

#sidebar .sidebar-item,
.sidebar-group {
  cursor: pointer;
  padding: 0 0 0 10px;
}

#sidebar .sidebar-group > .sidebar-item {
  cursor: pointer;
  padding: 0 0 0 15px;
}

#sidebar .sidebar-item a {
  color: black;
  display: block;
}

#sidebar .sidebar-item:hover,
.sidebar-item:hover a,
.sidebar-item a:hover {
  background-color: var(--main-color);
  color: white !important;
  font-weight: normal;
  text-decoration: none;
}

#sidebar .sidebar-item.active {
  font-weight: bold;
}

#sidebar .sidebar-link-label.active {
  font-weight: bold;
}

#sidebar .icon {
  width: 20px;
  display: inline-block;
}

#sidebar .sidebar-footer {
  position: absolute;
  bottom: 0;
  color: #a0a0a0;
  /*color: white;*/
  font-size: 12px;
  border-top: 1px solid rgba(200, 200, 200, 0.5);
  /*background: var(--main-color);*/
  /*background: #06cac7;*/
  width: 100%;
  text-align: left;
  padding: 5px 10px;
  /*opacity: 0.9;*/
  /*zoom: var(--main-zoom);*/
}

#main {
  /*margin-left: 10%;*/
  display: inline-block;
  width: 88%;
  vertical-align: top;
  padding: 0;
  position: relative;
  background-color: #fff;
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/
  height: 90vh;
  /*zoom: var(--main-zoom);*/
}

/*#main::-webkit-scrollbar {*/
/*display: none;*/
/*}*/

#main .main-header {
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--main-color);
  padding: 8px 0 8px 20px;
  font-weight: bold;
  vertical-align: middle;
  /*zoom: var(--main-zoom);*/
}

#main .main-header .left-icons {
  display: inline-block;
  cursor: pointer;
  margin: 0 20px 0 0;
}

#main .main-body {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: var(--main-body-margin);
  width: calc(var(--main-body-width) - 40px);
  /*zoom: var(--main-zoom);*/
  font-size: 20px;
}

.chinese ::placeholder {
  color: #c0c0c0;
  opacity: 1;
  font-size: 16px;
  font-style: normal !important;
}

.chinese ::-ms-input-placeholder {
  color: #c0c0c0;
  font-size: 16px;
  font-style: normal !important;
}

.chinese ::-ms-input-placeholder {
  color: #c0c0c0;
  font-size: 16px;
  font-style: normal !important;
}

::placeholder {
  color: #c0c0c0;
  opacity: 1;
  font-size: 16px;
}

::-ms-input-placeholder {
  color: #c0c0c0;
  font-size: 16px;
}

::-ms-input-placeholder {
  color: #c0c0c0;
  font-size: 16px;
}

.chinese .esp-select__placeholder {
  font-style: normal !important;
  color: #c0c0c0 !important;
}

.chinese .table-error {
  font-style: normal !important;
}

.right-icons {
  display: inline-block;
  float: right;
  margin-right: 10px;
  margin-top: -2px;
}

.right-icons img {
  padding: 0 10px;
  cursor: pointer;
  margin-top: -5px;
  max-width: 50px;
}

@media only screen and (max-device-width: 480px) {
  .right-icons img {
    padding: 0 10px;
    cursor: pointer;
    margin-top: -80px !important;
    max-width: 50px;
  }
}

@media only screen and (max-device-width: 980px) {
  .right-icons img {
    padding: 0 10px;
    cursor: pointer;
    margin-top: -10px !important;
    max-width: 50px;
  }

  .sleep-trend .title {
    margin-top: 0 !important;
    padding-left: 10px !important;
  }
}

.middle-icon {
  display: inline-block;
  margin-left: 24%;
  margin-right: 0;
  padding: 0 5%;
}

.middle-icon span {
  padding: 0 10px;
}

.appbar .alert-img {
  margin-right: 20px;
  display: inline-block;
  cursor: pointer;
  margin-top: -4px;
  /*width: 160px;*/
}

.alert-img img {
  width: 115px;
}

.alert-img.disconnected-img img {
  width: 35px;
  margin-top: -5px;
}

.appbar .esp-modal {
  z-index: 1001;
}

.appbar .esp-modal .modal-content {
  min-height: 85px;
}

.appbar .esp-modal .modal-body {
  height: 85px;
  min-height: 85px;
}

.appbar .esp-modal .modal-footer {
  text-align: center;
}

.appbar .esp-modal .modal-footer button {
  background-color: white;
  border: 0;
  color: #007bff;
  text-decoration: underline;
}

.esp-loading {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 1900px;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgba(100, 100, 100, 0.5);
}

.esp-loading .spinner {
  display: block;
  position: fixed;
  z-index: 10000;
  top: 40%;
  left: 49%;
  opacity: 1;
  color: var(--main-color);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.backIcon {
  margin-right: 15px;
  cursor: pointer;
  margin-top: -3px;
}

.gw-spinner {
  /*position: fixed;*/
  /*left: 47%;*/
  /*top: 40%;*/
  width: 70px;
}

.loading-img-wrapper {
  margin: 40vh 0 0 0;
  text-align: center;
}

.status-loading {
  width: 50px;
  height: 50px;
}

.sidebar-link-label {
  letter-spacing: 0;
}

.hover-white {
  background-color: #f7f6f6 !important;
}

/*@media only screen and (min-width: 1700px) {*/
/*#sidebar {*/
/*font-size: 20px;*/
/*width: 250px !important;*/
/*}*/
/*#main {*/
/*width: calc(100% - 250px) !important;*/
/*}*/
/*.system-id {*/
/*left: 250px*/
/*}*/
/*}*/

/*@media only screen and (max-width: 1400px) {*/
/*#sidebar {*/
/*width: 15% !important;*/
/*max-height: calc(155vh) !important;*/
/*font-size: 16px;*/
/*height: 97vh;*/
/*}*/

/*#main {*/
/*width: 85% !important;*/
/*}*/
/*.system-id {*/
/*left: 15%;*/
/*}*/

/*.device .left0 {*/
/*padding-right: 15px !important;*/
/*}*/

/*.device .right0 {*/
/*padding-left: 15px !important;*/
/*}*/

/*.device .select {*/
/*width: 250px !important;*/
/*min-width: 250px !important;*/
/*}*/

/*.device .slitting {*/
/*width: 100% !important;*/
/*}*/

/*.dashboard .error {*/
/*max-width: 35% !important;*/
/*}*/
/*}*/

/*@media only screen and (max-width: 800px) {*/
/*!*.sidebar-link-label {*!*/
/*!*display: none;*!*/
/*!*}*!*/

/*!*#sidebar .sidebar-group {*!*/
/*!*padding-left: 0;*!*/
/*!*}*!*/

/*!*#sidebar .sidebar-group > .sidebar-item {*!*/
/*!*padding-left: 10px;*!*/
/*!*}*!*/

/*#sidebar {*/
/*width: 23% !important;*/
/*max-height: calc(155vh) !important;*/
/*font-size: 14px;*/
/*}*/

/*#sidebar .sidebar-header,*/
/*#sidebar .sidebar-header-left {*/
/*padding-top: 10px;*/
/*}*/

/*#main {*/
/*width: 77% !important;*/
/*}*/

/*.system-id {*/
/*left: 23%;*/
/*}*/

/*!*#sidebar .sidebar-header {*!*/
/*!*display: none;*!*/
/*!*}*!*/

/*!*#sidebar .sidebar-item.active {*!*/
/*!*background-color: #FE7069;*!*/
/*!*}*!*/

/*!*#sidebar .sidebar-item.active svg {*!*/
/*!*color: white;*!*/
/*!*}*!*/
/*}*/
