.esp-modal {
  width: 100vw;
  height: 100vh;
  background-color: rgb(100, 100, 100, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.react-draggable {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
}

.show {
  /*display: block !important;*/
  /*margin: 10% auto !important;*/
}

.esp-modal .modal-dialog {
  margin: 10% 43%;
}

.esp-modal .modal-content {
  /*cursor: move;*/
  width: 345px;
  min-height: 375px;
  border-radius: 0;
}

.esp-modal .modal-header {
  display: block;
  min-height: 54px;
}

.esp-modal .modal-header .modal-title {
  color: black;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.esp-modal .modal-body {
  background-color: #ebebeb;
  color: black;
  font-size: 16px;
  min-height: 257px;
}

.esp-modal .modal-body p {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 10px;
}

.esp-modal .modal-body .group {
  margin: 20px 0;
}

.esp-modal .modal-body .symbol {
  margin-left: 25px;
  margin-right: 5px;
  color: red;
  display: inline-block;
}

.esp-modal .modal-body .label {
  margin-left: 0;
  margin-right: 5px;
  display: inline-block;
}

.esp-modal .modal-body .inline {
  width: 35%;
}

.esp-modal .modal-body input {
  width: 150px;
  height: 35px;
  padding: 0 30px;
}

.esp-modal .modal-body .value {
  font-weight: bold;
}

.esp-modal .modal-footer {
  color: black;
  font-size: 16px;
  height: 64px;
  display: block;
  padding-left: 40px;
  padding-right: 40px;
}

.esp-modal .modal-footer button {
  font-size: 16px;
  margin: 5px 28px;
  width: 75px;
  height: 28px;
  color: white;
  background-color: #fe7069;
  border: 1px solid #fe7069;
  border-radius: 3px;
}

.esp-modal .calendar .react-calendar {
  min-width: 300px;
  margin: -20px 0 0 65px;
}

@media only screen and (max-width: 800px) {
  .esp-modal .modal-dialog {
    margin: 10% 34%;
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between buttons */
  align-items: center;
  margin-top: 20px;
}

.blue-button {
  background-color: #007bff; /* Blue color */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%; /* Full width for better alignment */
}

.blue-button:hover {
  background-color: #0056b3;
}
