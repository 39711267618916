/* General styling */
.intercom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Optional: darken the background */
  z-index: 999; /* Ensure it's above other content */
}

.intercom {
  background-color: black;
  border: 2px solid #00ff00;
  border-radius: 10px;
  width: 300px;
  font-family: Arial, sans-serif;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.patient-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #666;
  color: white;
  font-weight: bold;
  padding: 5px;
}

.patient-name {
  font-size: 18px;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.close-button img {
  width: 20px;
  height: 20px;
}
.nurse-speaker {
  width: 20px;
  height: 20px;
}
.nurse-item-image {
  width: 20px;
  height: 20px;
}

/* User list styling */
.user-list {
  width: 100%;
}

.user-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 10px;
  padding: 0;
  list-style: none;
}

/* User item background color based on status */
.user-item {
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

/* Specific status colors */
.user-item.online {
  background-color: #ededed; /* Light grey for online */
  color: black; /* Text color for online users */
}

.user-item.offline {
  background-color: #7a7a7a; /* Dark grey for offline */
  color: white; /* Text color for offline users */
}

/* Mute button styling */
.mute-button {
  width: 50px;
  height: 50px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mute-button img {
  width: 100%;
  height: auto;
}

.dialog-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Take remaining height */
  padding: 10px;
  width: 100%;
  height: 250px;
  background-color: black;
  color: white; /* Optional: to make text readable */
}

.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: auto; /* Stick footer to the bottom */
  padding: 10px 0;
}

.dialog-content {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100; /* Ensures it's above other elements */
}

.loading-spinner {
  width: 100px;
  height: 100px;
  /* You can adjust the size of the loading spinner */
}
