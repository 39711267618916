.nurse-call {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  position: relative;
  width: 600px; /* Fixed width */
  height: 600px; /* Fixed height */
  border-radius: 10px;
  margin: auto; /* Centered in its parent container */
  overflow: hidden; /* Hides any overflow */
  padding: 0; /* Reset padding to ensure it doesn't affect inner alignment */
  box-sizing: border-box; /* Include padding and border in the element's size */
  border: 2px solid #00ff00; /* Adds a 2px solid green border */
}

.nurse-call > * {
  width: 100%; /* Ensures all children take up 100% of the parent's width */
  max-width: 100%; /* Prevents any child from exceeding the parent's width */
  box-sizing: border-box; /* Include padding and border in the element's size */
}

.nurse-call .patient-container {
  display: flex; /* Establishes a flex container */
  align-items: center; /* Vertically centers the items in the container */
  justify-content: space-between; /* Places the button at the far right */
  width: 100%; /* Ensures the container spans the full width of its parent */
  background-color: #444444;
}

.nurse-call .patient-name {
  flex-grow: 1; /* Allows the patient name to take up any available space */
  display: flex;
  align-items: center; /* Centers the content vertically within 'patient-name' */
  overflow: hidden; /* Prevents overflowing content from disrupting the layout */
}

.nurse-call close-button {
  margin-left: 10px; /* Adds some space between the text and the button */
  flex-shrink: 0; /* Prevents the button from shrinking */
}

.nurse-call .patient-name {
  height: 14.5%; /* Relative height based on the container */
  color: #fff;
  height: 100%;
  /* Full width of the container */
  border-radius: 5px 8px 0 0;
  text-align: left;
  align-items: center;
  display: flex;
  font-size: 37.5px; /* Calculated as 450px/12 */
  font-weight: bold;
  padding: 0 15px; /* Padding inside the patient name area */
}

.nurse-call .patient-name span {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Adds ellipsis (...) if text is too long */
  display: flex;
  align-items: center;
  width: 100%; /* Ensures the span takes up full width of the parent */
}

.nurse-call .status {
  height: 23%;
  display: flex;
  align-items: center;
}

.nurse-call .status > div {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 20px;
  border-radius: 5px;
  height: 94%;
}

.nurse-call .status > div:nth-child(1) {
  border: 1px solid #f6991e;
  margin: 5px 3px 0 5px;
}

.nurse-call .status .status-resp div {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nurse-call .status > div:nth-child(2) {
  border: 1px solid #62f583;
  margin: 5px 5px 0 3px;
}

.nurse-call .status .status-pr div {
  text-align: center;
  width: 50%;
}

.nurse-call .status img {
  width: 56.25px; /* Calculated as 450px/8 */
}

.nurse-call .status .status-value-pr {
  color: rgb(98, 245, 131);
}

.nurse-call .status .status-value-resp {
  color: rgb(246, 153, 30);
}

.nurse-call .status .status-value-pr,
.nurse-call .status .status-value-resp {
  font-size: 56.25px; /* Same as image width to maintain design harmony */
  font-family: "Roboto", sans-serif !important;
  font-weight: 600;
}

.nurse-call .icons {
  display: flex;
  flex-direction: column;
  align-items: center; /* This centers the children horizontally */
  width: 100%; /* This ensures the container takes the full width of its parent */
}

.nurse-call .icons .mic-button {
  background-color: white; /* Ensures the background is white */
  border: none;
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  width: 100%; /* Makes the button take full width of its container */
  height: 170px; /* Fixed height for the button */
  display: flex;
  align-items: center; /* Centers items vertically */
  justify-content: center; /* Centers items horizontally */
  flex-direction: column; /* Stack items vertically */
  text-align: center; /* Ensures text is centered if it wraps */
}

.nurse-call .icons .mic-button img {
  width: auto; /* Keeps the image at a fixed width */
  height: 120px; /* Maintains the aspect ratio of the image */
}

.nurse-call .icons .mic-button span {
  color: #929292; /* Text color */
  font-size: 16px; /* Text size */
  font-weight: bold; /* Text weight */
}

.icons.disconnected {
  /*width: 98%;*/
  margin: 5px !important;
  height: 85.5% !important;
}

.icons.no-data {
  height: 85.5% !important;
}

.nurse-call .icons.no-data .icons-wrapper {
  height: 88% !important;
}

.nurse-call .icons.no-data .icons-wrapper img {
  height: calc((var(--tile-height) + var(--tile-width-px)) / 12);
}

.nurse-call .icons.border-sleeping {
  border: 1px solid #6ae1ff !important;
}

.nurse-call .icons.border-absent {
  border: 1px solid #f5ea08 !important;
}

.nurse-call .icons.border-awake {
  border: 1px solid #f6991e !important;
}

.nurse-call .icons.border-eol {
  border: 1px solid #e61610 !important;
}

.nurse-call .icons.border-sleeping .bed-name {
  background-color: #6ae1ff !important;
}

.nurse-call .icons.border-absent .bed-name {
  background-color: #f5ea08 !important;
}

.nurse-call .icons.border-awake .bed-name {
  background-color: #f6991e !important;
}

.nurse-call .icons.border-eol .bed-name {
  background-color: #e61610 !important;
  color: #fff;
}

.nurse-call.sound-icon-trigger .icons.bed-e1 {
  border: 1px solid #e61610 !important;
}

.nurse-call.sound-icon-trigger .icons.bed-e1 .bed-name {
  background-color: #e61610 !important;
  color: #fff;
}

.nurse-call .layout-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.nurse-call .icons-wrapper-A {
  flex: 0 0 80%; /* Allocate 80% of the parent width */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  height: 100%;
}

.nurse-call .icons-wrapper-B {
  flex: 0 0 20%; /* Allocate remaining 20% of the parent width */
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  justify-content: space-between; /* Distribute space evenly */
  align-items: center; /* Align items in the center horizontally */
  height: 100%;
  padding: 20px;
}

.nurse-call .icons-wrapper-B .volume-bar {
  flex: 0 0 60%; /* 80% of parent container's height */
  width: 100%; /* Full width of its container */
  padding: 5px;
}

.nurse-call .icons-wrapper-B .speaker-icon {
  flex: 0 0 10%; /* Remaining 20% of parent container's height */
  width: 40%; /* Full width of its container */
  margin: 10px;
}

.icons.updating {
  /*width: 98%;*/
  margin: 5px !important;
  height: 85% !important;
}

.nurse-call .icons .bed-name {
  width: 100%;
  background-color: #a0a0a0;
  color: #000;
  font-size: 30px; /* Calculated as (450px + 600px)/30 */
  font-weight: 700;
  text-align: center;
  padding: 18.75px 0 13.23px; /* Responsive padding calculation */
  height: 46.39px; /* Calculated as (450px / 9.7) + 0.5 */
  border-radius: 0 0 2px 2px;
}

/* .nurse-call .icons.updating .bed-name {
    height: calc((var(--tile-height) / 9.7) + 3.5px);
}

 .nurse-call .icons.updating .bed-name.n-beds-8 {
    height: calc((var(--tile-height) / 9.7) + 4px);
} */

.nurse-call .icons .bed-name span {
  vertical-align: middle;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nurse-call .icons .left-icon,
.nurse-call .icons .right-icon {
  width: 50%;
  justify-items: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.nurse-call .icons img {
  /* Fixed width */
  height: 200px; /* Height auto for maintaining aspect ratio */ /* Maximum height to not exceed */
  margin: auto; /* Margin auto for automatic alignment and centering */
}
.nurse-call .icons .call-button {
  height: 100px;
  width: 100px;
}

/*@media only screen and (max-device-width: 1400px) {*/
/* .nurse-call .icons img {*/
/*width: auto;*/
/*height: calc(var(--tile-height) / 2.5);*/
/*margin: auto auto;*/
/*}*/
/*}*/

/*@media only screen and (max-device-width: 980px) {*/
/* .nurse-call .icons img {*/
/*width: auto;*/
/*height: calc(var(--tile-height) / 3.5);*/
/*margin: auto auto;*/
/*}*/
/*}*/

/*@media only screen and (max-device-width: 400px) {*/
/* .nurse-call .icons img {*/
/*width: auto;*/
/*height: calc(var(--tile-height) / 4.5);*/
/*margin: auto auto;*/
/*}*/
/*}*/

.nurse-call .beds {
  height: 11%;
  display: flex;
  font-size: calc((var(--tile-height) + var(--tile-width-px)) / 30);
}

.nurse-call .beds div:nth-child(1) {
  border-radius: 0 0 0 5px;
  border-right: 1px solid #000;
}

.nurse-call .beds div:nth-child(2) {
  border-radius: 0 0 5px 0;
  border-left: 1px solid #000;
}

.nurse-call .beds div {
  width: 50%;
  background-color: rgb(41, 171, 224);
  color: white;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nurse-call .beds span {
  margin: auto;
}

.select-intercom img {
  width: 36px;
  margin-top: -5px;
  margin-right: 5px;
}
