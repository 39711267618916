.esp-full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1); /* Semi-transparent black overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999000;
}

.overlay-content {
  text-align: center;
  color: white;
}

.overlay-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.overlay-message {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.overlay-loading {
  width: 50px;
  height: 50px;
}
