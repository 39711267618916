.iresp-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  display: flex;
  z-index: 9999;
}

.iresp-modal .modal-dialog {
  margin: 15% 43%;
}

.iresp-modal .modal-content {
  width: 310px;
  min-height: 295px;
  border-radius: 1px 1px 1px;
  box-shadow: 5px 5px 10px #888888;
}

.about-modal .modal-content,
.about-modal-4P .modal-content {
  zoom: var(--main-zoom);
}

.about-modal .modal-title,
.about-modal-4P .modal-title {
  cursor: default;
  user-select: none;
}

.iresp-modal .modal-header {
  display: block;
  height: 64px;
  text-align: center;
}

.iresp-modal .modal-header .modal-title {
  color: black;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}

.iresp-modal .modal-body {
  background-color: #ebebeb;
  color: black;
  min-height: 150px;
  padding: 10px 30px;
  font-size: 20px;
}

.iresp-modal .modal-footer {
  min-height: 75px;
  text-align: center;
  display: block;
}

.iresp-modal .modal-footer button {
  /*color: #fff;*/
  /*background-color: #43cabd;*/
  /*border: 1px solid #43cabd;*/
  /*border-radius: 3px;*/
  color: #007bff;
  text-decoration: underline;
  background-color: white;
  border: none;
  min-width: 30px;
  margin: 10px 40px;
}

.iresp-modal .color-red {
  color: #e61610;
  font-weight: bold;
}
